import type { TDomainsFilters } from '@/server/services/Domains';
import type { ClientContext } from '@/client/context';

export const getCurrentFilters = (context): TDomainsFilters => {
    return {
        ...(context.request.data || {}),
        page: context.request.data.page ? parseInt(context.request.data.page) : 1,
        //keywords: keywords || undefined
    };
}

export const encodeSearch = (kw: string) => encodeURIComponent(kw).replaceAll('%20', '+')

// TODO: Move as client app service ?
export const buildSearchUrl = (filters: Partial<TDomainsFilters>, { Router, context }: ClientContext, relative: boolean = true) => {

    const currentFilters = getCurrentFilters(context);

    if (relative)
        filters = { ...currentFilters, ...filters };

    if (filters.keywords !== undefined) {
        const { keywords, ...otherFilters } = filters;
        return Router.url('/domains/' + encodeSearch(keywords), otherFilters, false);
    } else if (filters.sector !== undefined) {
        const { sector, ...otherFilters } = filters;
        return Router.url('/domains/sector/' + encodeSearch(sector), otherFilters, false);
    } else if (filters.tld !== undefined) {
        const { tld, ...otherFilters } = filters;
        return Router.url('/domains/tld/' + encodeSearch(filters.tld), otherFilters, false);
    } else
        return Router.url('/domains', filters, false);
}