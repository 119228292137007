/*----------------------------------
- DEPENDANCES
----------------------------------*/

// Npm
import React, { JSX } from 'react';

// Core
import useContext from '@/client/context'; 

// Core components
import { Button } from '@client/components';

/*----------------------------------
- TYPES
----------------------------------*/

import type { domains as Domain } from '@/server/models';

// With basic div attributes
type TProps = JSX.HTMLAttributes<HTMLDivElement> & {
    domain: Domain
    searchId?: string,
    small?: boolean
};

/*----------------------------------
- COMPONENT
----------------------------------*/
export default ({ domain, searchId, small, ...props }: TProps) => {

    /*----------------------------------
    - INIT
    ----------------------------------*/

    const { api } = useContext();

    const [isFavorite, setIsFavorite] = React.useState(domain.isFavorite);

    const hasChanged = React.useRef(false);

    let clickUrl = '/domain/' + domain.newId + '/go';
    if (searchId)
        clickUrl += '?searchId=' + searchId;

    /*----------------------------------
    - ACTIONS
    ----------------------------------*/

    React.useEffect(() => {
        
        if (hasChanged.current)
            api.post('/user/favorite', { domainId: domain.newId, isFavorite }).run().catch(() => {
                hasChanged.current = false;
                setIsFavorite(!isFavorite);
            });

        hasChanged.current = true;

    }, [isFavorite]);

    /*----------------------------------
    - RENDER
    ----------------------------------*/
    return (
        <article class={"card bg img col pd-1 " + 
            (domain.bgTone + 'Tone ') + 
            (small ? 'w-3 h-1 ' : 'h-2') +
            (props.className || '')
        } style={/*domain.newId === null ? {
            backgroundColor: domain.color,
            backgroundImage: domain.gradient?.split(';').map(g => {
                const [x, y, color] = g.split(',');
                return `radial-gradient(at ${x}% ${y}%, #${color} 0px, transparent 55%)`;
            }).join(', ')
        } : */{}}>

            <a href={clickUrl} target="_blank" rel="nofollow">
                Buy Domain
            </a>
    
            <ul class="row sp-btw menu row-1 al-top">
    
                <li>
                    <Button icon={isFavorite ? /* @icon */"solid/heart" : /* @icon */"regular/heart"} 
                        size="s" onClick={() => setIsFavorite(!isFavorite)} 
                        aria-label="Save this domain name in my favorites" />
                </li>
    
                <li class="row">
                    {/*domain.usageFrequency && <>
                        {domain.usageFrequency}
    </>*/}
                </li>
            </ul>

            {/*domain.newId === null ? (
                <img src={domain.logoUrl} alt="Domain Name Logo" class="domainName pdh-1" />
            ) : */(
                <div class="contLogo">
                    <img loading="lazy" title="View domain name" src={domain.logoUrlV2} alt="Domain name logo"  />
                </div>
            )}
    
            <ul class="row sp-btw row-1 al-bottom">
    
                <li class="row sp-05">
                    <i src="mouse-pointer" />
                    {domain.viewsCount}
                </li>
    
                {!!domain.purchasePrice && <>
                    <li class="price">
                        {domain.purchasePrice}
                    </li>
                </>}
            </ul>

            {/* <ul class="details col txt-left">
                <li class="col al-fill sp-05">
                    Positivity
                    <div class="progressbar">
                        <div class="progress" style={{ width: '70%' }} />
                    </div>
                </li>
                <li class="col al-fill sp-05">
                    Popularity
                    <div class="progressbar">
                        <div class="progress" style={{ width: '70%' }} />
                    </div>
                </li>
                <li class="col al-fill sp-05">
                    Brandability
                    <div class="progressbar">
                        <div class="progress" style={{ width: '70%' }} />
                    </div>
                </li>
            </ul> */}
    
        </article>
    )
}