/*----------------------------------
- DEPENDANCES
----------------------------------*/

// Npm
import React from 'react';
import dayjs from 'dayjs';

// Core
import { Router } from '@app';
import { Button, Input } from '@client/components';
import useContext from '@/client/context';
import useHeader from '@client/pages/useHeader';

// App components
import DomainCard from '@/client/components/DomainCard';
import Filters from './Filters';

// App
import { getCurrentFilters, buildSearchUrl } from '@/common/libs/filters';

/*----------------------------------
- TYPES
----------------------------------*/

/*----------------------------------
- CONTROLEUR
----------------------------------*/
export default ({ domains, stats }: { 
    domains: TDomainsLists,
    stats: TDomainsStats
}) => {

    /*----------------------------------
    - INIT
    ----------------------------------*/

    const { api, app, context, page, toast } = useContext();

    const [openFilters, setOpenFilters] = React.useState(false);

    const currentFilters = getCurrentFilters(context);

    const lastPage = Math.floor(domains.count / 16);
    let pagesDisplayRange = 3;
    const pageListMin = Math.max(2, currentFilters.page - pagesDisplayRange);
    
    const pageListMax = Math.min(lastPage - 1, currentFilters.page + pagesDisplayRange);
    if (pageListMax - pageListMin < pagesDisplayRange)
        pagesDisplayRange = pageListMax - pageListMin;

    const pagesList = [1]
    for (let i = pageListMin; i <= pageListMax; i++) {
        pagesList.push(i)
    }
    pagesList.push(lastPage);

    /*----------------------------------
    - ACTIONS
    ----------------------------------*/

    const saveSearch = e => api.post('/user/search', { filters: JSON.stringify(currentFilters) }).then(() => {
        toast.success('Search saved. You will receive notifications when new domains match your criteria.');
    });
    
    const search = keywords => {

        keywords = keywords.trim();

        if (!keywords)
            return toast.error("Please enter at least one keyword to search for domains.");

        app.Router.go( buildSearchUrl({ keywords }, context), {}, { newTab: false });
    }

    /*----------------------------------
    - RENDER
    ----------------------------------*/
    return <>
        <section id="Domains" class={"col" + (openFilters ? ' openFilters' : '')}>
            <header class="row card bg silver pd-1">

                <div class="row al-left col-1">
                    <h2 class="desktop">One Word Domains</h2>
                    <Button icon="sliders-h" class='mobile' onClick={() => setOpenFilters(c => !c)} />
                </div>

                <Input title="AI Search domains in 1-3 words" className="w-2-4"
                    icon="search" value={currentFilters.keywords}
                    maxLength={150} onPressEnter={search} />

                <div class="row al-right col-1">

                    {/* <Button>
                        Sort by
                    </Button>

                    <Button>
                        Grid
                    </Button> */}

                    <Button icon="bell" 
                        async onClick={saveSearch} 
                        aria-label="Save this search" />

                </div>
            </header>
            <div class="row al-top">

                <Filters stats={stats} close={() => setOpenFilters(false)} />

                <div class="col-1 col">

                    {domains.count === 0 ? (
                        <div class="card">
                            We would find any quality domain matching your filters. Please try again with different filters.
                        </div>
                    ) : <>
                        <div class="Domains grid">
                            {domains.list.map(domain => (
                                <DomainCard domain={domain} searchId={domains.searchId} />
                            ))}
                        </div>

                        <footer class="row pdv-3 card bg silver">
                            <ul class="row">
                                {pagesList.map((pageNum) => (
                                    <li>
                                        <Button link={buildSearchUrl({ page: pageNum }, context)} 
                                            type="secondary" active={currentFilters.page === pageNum}
                                            rel="nofollow">
                                            {pageNum}
                                        </Button>
                                    </li>
                                ))}
                            </ul>
                        </footer>
                    </>}

                </div>
            </div>
        </section>
    </>
}